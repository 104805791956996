<script setup lang="ts">
const props = defineProps<{
  error: {
    statusCode: number
    message: string
    stack: string
  }
}>()

if (props.error.statusCode !== 404) {
  console.error('Error Message', props.error.message)
  console.error('Error Stack', props.error.stack)
}
</script>

<template>
  <div class="flex justify-center items-center px-8 h-[50vh]">
    <div class="container text-center">
      <h1 class="heading-1 pb-8">
        Page Not Found
      </h1>
      <p class="font-sans prose prose-lg prose-invert mx-auto">
        The page you are looking is for might have been removed, had its name changed, or temporarily unavailable.
      </p>
      <div class="pt-8 flex justify-center">
        <InterfaceButtons reveal-color="bg-teal-500" to="/" size="large" class="border border-zinc-500 pointer-events-auto">
          Sure grandpa. Let's get you to bed.
          <template #revealText>
            It's been a long one...
          </template>
        </InterfaceButtons>
      </div>
    </div>
  </div>
</template>
