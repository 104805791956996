<script lang="ts" setup>
import { timeline } from 'motion'

// Define props defaults in typescript
export interface Props {
  revealColor?: string | null
  revealHex?: string | null
  to?: string
  size?: 'small' | 'medium' | 'large'
}

const props = withDefaults(defineProps<Props>(), {
  revealColor: 'bg-rose-500',
  size: 'large',
})

const mouseStateOn = ref(false)

const iconWrapper: Ref<HTMLElement | null> = ref(null)
const icon: Ref<HTMLElement | null> = ref(null)
const button: Ref<HTMLElement | null> = ref(null)
const reveal: Ref<HTMLElement | null> = ref(null)
const revealText: Ref<HTMLElement | null> = ref(null)

onMounted(() => {
  if (!button.value || !revealText.value)
    return

  const buttonWidth = button.value.clientWidth
  revealText.value.style.width = `${buttonWidth}px`
})

watch(() => mouseStateOn.value, (mouseStateOn) => {
  if (!button.value || !revealText.value || !icon.value || !iconWrapper.value)
    return

  // Button width
  const buttonWidth = button.value.clientWidth
  const iconWidth = icon.value.clientWidth

  if (mouseStateOn) {
    revealText.value.style.minWidth = `${buttonWidth + 20}px`

    // Make a timeline to bounce the icon wrapper before the reveal
    const sequence: any[] = []
    sequence.push(
      [
        iconWrapper.value,
        { transform: ['scale(1)', 'scale(1.1)', 'scale(1)'] },
        { easing: 'ease-in', duration: 0.3 },
      ],
      { name: 'reveal', at: '0.3' },
      [
        iconWrapper.value,
        { transform: [`translateX(${(buttonWidth - iconWidth - 2)}px)`] },
        { duration: 0.5, at: 'reveal' },
      ],
      [
        icon.value,
        { transform: [`rotate(0)`, `rotate(360deg)`] },
        { duration: 0.5, at: 'reveal' },
      ],
      [
        reveal.value,
        { width: [`100%`] },
        { duration: 0.6, at: 'reveal' },
      ],
    )
    timeline(sequence)
  }
  else {
    const sequence: any[] = []
    sequence.push(
      { name: 'hide', at: '0' },
      [
        iconWrapper.value,
        { transform: [`translateX(0)`] },
        { duration: 0.3, at: 'hide' },
      ],
      [
        icon.value,
        { transform: [`rotate(360deg)`, `rotate(0)`] },
        { duration: 0.3, at: 'hide' },
      ],
      [
        reveal.value,
        { width: [`0`] },
        { duration: 0.3, at: 'hide' },
      ],
    )
    timeline(sequence)
  }
})

const router = useRouter()

function goToLink() {
  console.log('goToLink', props.to)

  if (props.to)
    router.push(props.to)
}

const revealHexObj = computed(() => {
  if (props.revealHex)
    return { backgroundColor: props.revealHex }
  return {}
})
</script>

<template>
  <button ref="button" class="relative overflow-hidden font-sans text-xs font-light tracking-wider uppercase rounded-full flex items-center focus:outline-none border border-white/20" @mouseenter="mouseStateOn = true" @mouseleave="mouseStateOn = false" @click="goToLink">
    <div ref="iconWrapper" class="relative z-30">
      <div
        ref="icon" class="bg-white text-black rounded-full flex justify-center items-center origin-center"
        :class="{
          'w-7 h-7': size === 'small',
          'w-8 h-8': size === 'medium',
          'w-10 h-10': size === 'large',
        }"
      >
        <slot name="icon">
          <Icon name="heroicons:arrow-right" class="w-4 h-4" />
        </slot>
      </div>
    </div>
    <div v-if="$slots.default" class="pl-3 pr-6">
      <slot />
    </div>

    <div v-if="$slots.revealText" ref="reveal" class="absolute top-0 left-0 bottom-0 flex items-center overflow-hidden w-[0]" :class="[revealColor]" :style="[revealHexObj]">
      <div ref="revealText" class="pr-12">
        <slot name="revealText">
          <slot />
        </slot>
      </div>
    </div>
  </button>
</template>

<style>

</style>
